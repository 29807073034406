export function testCall(code) {
    return fetch(`${process.env.REACT_APP_API_HOST}/user/test`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            code: code
        })
    }).then(response => {
        return response.json()
    }).catch(error => {
        console.error(error);
        return {
            error: error
        }
    })
}

export function getProfile(code) {
    return fetch(`${process.env.REACT_APP_API_HOST}/user/profile`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            code: code
        })
    }).then(response => {
        return response.json()
    }).catch(error => {
        console.error(error);
        return {
            error: error
        }
    })
}