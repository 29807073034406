export function checkStatus(user_token) {
    return fetch(`${process.env.REACT_APP_API_HOST}/ec2/status`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            user_token: user_token
        })
    }).then(response => {
        return response.json()
    }).catch(error => {
        console.error(error);
        return {
            error: error
        }
    })
}

export function stopInstance(user_token) {
    return fetch(`${process.env.REACT_APP_API_HOST}/ec2/stop`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            user_token: user_token
        })
    }).then(response => {
        return response.json()
    }).catch(error => {
        console.error(error);
        return {
            error: error
        }
    })
}