import styled from 'styled-components';
import { Button, Flex } from '../../UI'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;

  overflow: hidden;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const SelectBtn = styled(Button)`
  width: 100px;
  margin: 0 10px; 

  box-sizing: border-box;

  border: ${p => p.selected ? '2px solid black' : '2px solid transparent'};
`

export const ScreenThumb = styled.div`
  width: 100px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
  
  background-color: #212121;
  color: #FFFFFF;

`

export const Tracker = styled.div`
  width: 100%;
  height: 60px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1px;

  background-color: #424242;
`

export const Track = styled.div`
  width: ${p => p.width};
  height: 58px;
  margin: ${p => p.margin};

  display: flex;
  justify-content: center;
  align-items: center;
  
  background-color: ${p => p.color || 'red'};
`

export const ShadowFlex = styled(Flex)`
  width: calc(100% - 40px);
  overflow: show;
  margin: 30px auto;

  padding: 0;
  flex-direction: row;
  justify-content: flex-start;

  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    box-shadow: 0px 5px 7px -2px rgba(0, 0, 0, 0.2), 0px 12px 15px 2px rgba(0, 0, 0, 0.14), 0px 3px 23px 4px rgba(0, 0, 0, 0.12);
  }
`

export const TrackTitle = styled.p`
  width: 400px;
  margin: 0;

  display: flex;
  justify-content: center;

  color: #FFFFFF;
  font: Bold 24px/29px 'Segoe UI';

  border-right: 1px solid #FFFFFFBF;
`
export const Subtitle = styled.p`
  width: auto;
  margin: ${p => p.margin};


  color: #FFFFFF;
  text-align: ${p => p.textAlign};
  font: normal 16px/21px 'Segoe UI';
`

export const TrackType = styled.p`
  width: 150px;
  margin: 0;

  display: flex;
  justify-content: center;
  
  color: #FFFFFF;
  font: Bold 24px/29px 'Segoe UI';

  border-right: 1px solid #FFFFFFBF;
`

export const TrackTimer = styled.p`
  width: 250px;
  margin: 0;

  display: flex;
  justify-content: center;
  
  color: #FFFFFF;
  font: Bold 24px/29px 'Segoe UI';

  border-right: 1px solid #FFFFFFBF;
`

export const DeleteTrack = styled(Button)`
  width: 200px;
  height: 34px;
  margin: 0 40px 0 auto;
  padding: 0 12px; 

  box-sizing: border-box;
  color: #CCCCCC;

  background-color: #EE2222;
  opacity: 0.8;
  transition: all ease 0.5s;

  &:hover {
    opacity: 1;
    background-color: #EE2222;
    color: #FFFFFF;
  }
`
