import React, { useState, useEffect, useRef } from 'react';

// UTILS
import { convertSecondsToString } from '../../utils/functions'

// STYLED
import { BGContainer, Box, Flex, MenuTitle, Scrollable, Button, ModalOverlay, Select } from '../../UI'
import { Container, SelectBtn, ScreenThumb, Tracker, Track, ShadowFlex, TrackTitle, TrackType, TrackTimer, DeleteTrack, Subtitle } from './styles'


function Controls() {

  // MOCK
  const [tracks, setTracks] = useState([
    {
      id: "vdjg45jnh7hfr5n96g",
      name: "The expetacular video that shows a cool thing",
      duration: 148,
      type: 'video'
    },
    {
      id: "vdjg46jnh7hfr5n96g",
      name: "Lorem Ipsum ad dolorem it mun ad senso strictus",
      duration: 345,
      type: 'video'
    },
    {
      id: "vdjg46jnh7hfr5n97g",
      name: "A cool picture",
      duration: 0,
      type: 'image'
    },
    {
      id: "vdjg46jnh7hfr5n98g",
      name: "My RTMP",
      duration: 0,
      type: 'rtmp'
    }
  ])

  const [monitors, setMonitors] = useState([
    {
      id: "bhj56402ndf0g45n96g",
      name: "M1",
      tracks: []
    },
    {
      id: "bhj86402ndf0g45n96g",
      name: "M2",
      tracks: []
    },
    {
      id: "bhj76402ndf0g45n96g",
      name: "M3",
      tracks: []
    },
    {
      id: "bhj66402ndf0g45n96g",
      name: "M4",
      tracks: []
    }
  ])

  const [selectedMenu, setSelectedMenu] = useState('All')
  const [addTrackModal, setAddTrackModal] = useState(false)

  const [selectedTrack, setSelectedTrack] = useState(false)
  const [selectedTrackID, setSelectedTrackID] = useState(false)

  const [flowDuration, setFlowDuration] = useState(3600)

  // HANDLERS
  const handleCloseModal = () => {
    setSelectedTrack(false)
    setAddTrackModal(false)
  }

  const handleSelectTrack = (id) => {
    setSelectedTrackID(id)
    setSelectedTrack(tracks.filter(track => track.id === id)[0])
  }

  const handleAddTrack = () => {
    if (!selectedTrack) return

    let position = false

    let newMonitor = {
      ...monitors.filter((monitor, index) => {

        if (monitor.name === selectedMenu) {
          position = index
          return true
        }

        return false
      })[0]
    }

    selectedTrack.start = 0
    selectedTrack.end = 0

    newMonitor.tracks = [...newMonitor.tracks, selectedTrack]

    let newMonitors = [...monitors]
    newMonitors[position] = newMonitor

    setMonitors(newMonitors)
    setAddTrackModal(false)
  }

  const handleRemoveTrack = (index) => {

    let position = false

    let newMonitor = {
      ...monitors.filter((monitor, i) => {

        if (monitor.name === selectedMenu) {
          position = i
          return true
        }

        return false
      })[0]
    }

    newMonitor.tracks.splice(index, 1)

    let newMonitors = [...monitors]
    newMonitors[position] = newMonitor

    setMonitors(newMonitors)
  }

  // RENDERS
  const render = () => {
    switch (selectedMenu) {
      case 'All':
        return <Scrollable width="100%">
          <MenuTitle margin="40px auto 0 20px">Duration of show: {convertSecondsToString(flowDuration)}</MenuTitle>
          <MenuTitle margin="50px auto 0 20px">Flows:</MenuTitle>
          <ShadowFlex>
            <ScreenThumb>
              M1
            </ScreenThumb>
            <Tracker>
              <Track color="#567534" width="13.78%" />
              <Track color="#128558" width="3.78%" margin="0 0 0 5%" />
              <Track color="#765432" width="8.43%" margin="0 0 0 8.13%" />
              <Track color="#BF6D5B" width="40.78%" />
            </Tracker>
          </ShadowFlex>

          <ShadowFlex>
            <ScreenThumb>
              M2
            </ScreenThumb>
            <Tracker>
              <Track color="#765432" width="18.43%" margin="0 0 0 8.13%" />
              <Track color="#567534" width="3.78%" />
              <Track color="#765432" width="18.43%" margin="0 0 0 8.13%" />
              <Track color="#567534" width="3.78%" />
              <Track color="#567534" width="3.78%" />
            </Tracker>
          </ShadowFlex>

          <ShadowFlex>
            <ScreenThumb>
              M3
            </ScreenThumb>
            <Tracker>
              <Track color="#567534" width="3.78%" />
              <Track color="#765432" width="18.43%" margin="0 0 0 8.13%" />
              <Track color="#128558" width="23.78%" margin="0 0 0 5%" />
              <Track color="#765432" width="18.43%" margin="0 0 0 8.13%" />
            </Tracker>
          </ShadowFlex>

          <ShadowFlex>
            <ScreenThumb>
              M4
            </ScreenThumb>
            <Tracker>
              <Track color="#128558" width="23.78%" margin="0 0 0 5%" />
              <Track color="#567534" width="3.78%" />
              <Track color="#128558" width="23.78%" margin="0 0 0 5%" />
            </Tracker>
          </ShadowFlex>
        </Scrollable>

      case 'M1':
        return <Scrollable width="100%">
          <MenuTitle margin="0 auto 0 20px">Tracks:</MenuTitle>
          {monitors.filter(m => m.name === selectedMenu)[0].tracks.map((track, index) => {
            return <ShadowFlex>
              <Tracker>
                <Flex width="40px" height="100%" bgColor="#567534" padding="0" />
                <TrackTitle>{track.name}</TrackTitle>
                <TrackType>{track.type}</TrackType>
                <TrackTimer>{convertSecondsToString(track.start)}</TrackTimer>
                <TrackTimer>{convertSecondsToString(track.end)}</TrackTimer>
                <DeleteTrack onClick={() => handleRemoveTrack(index)}>Remove Track</DeleteTrack>
              </Tracker>
            </ShadowFlex>
          })}

          <Button onClick={() => setAddTrackModal(true)} color="#FFFFFF" width="calc(100% - 40px)" margin="10px auto" bgColor="#424242">
            + Add Track
          </Button>

          <MenuTitle margin="80px auto 0 20px">Final Flow:</MenuTitle>

          <ShadowFlex>
            <ScreenThumb>
              M1
            </ScreenThumb>
            <Tracker>
              <Track color="#567534" width="13.78%" />
              <Track color="#128558" width="3.78%" margin="0 0 0 5%" />
              <Track color="#765432" width="8.43%" margin="0 0 0 8.13%" />
              <Track color="#BF6D5B" width="40.78%" />
            </Tracker>
          </ShadowFlex>
        </Scrollable>

      default:
        break;
    }
  }

  return <BGContainer>
    <Container>
      <Box bgColor="#303030" width="80%" height="80%" justify="flex-start">
        <Flex minHeight="94px" margin="0 auto 40px" flexDirection="row">
          <SelectBtn selected={selectedMenu === 'All'} onClick={() => setSelectedMenu('All')}>
            All
          </SelectBtn>
          {monitors.map(monitor => {
            return <SelectBtn selected={selectedMenu === monitor.name} onClick={() => setSelectedMenu(monitor.name)}>
              {monitor.name}
            </SelectBtn>
          })}
        </Flex>
        {render()}
      </Box>
    </Container>

    <ModalOverlay isOpen={addTrackModal}>
      <Box shadow="0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)" bgColor="#424242" width="380px" height="50%" justify="flex-start" padding="30px 40px 20px">
        <MenuTitle>Add a Track</MenuTitle>
        <Subtitle margin="30px auto 0 0">Select:</Subtitle>
        <Select margin="10px auto 0 0" onChange={e => handleSelectTrack(e.target.value)} value={selectedTrackID} placeholder="Please select">
          <option value="">Select a track</option>
          {tracks.map(track => {
            return <option value={track.id}>{track.name}</option>
          })}
        </Select>

        <Flex width="100%" align="flex-start" margin="40px 0" padding="0">
          <Subtitle>Track Type: {selectedTrack && selectedTrack.type.toUpperCase()}</Subtitle>
          <Subtitle>Track Duration: {selectedTrack && convertSecondsToString(selectedTrack.duration)}</Subtitle>
        </Flex>

        <Flex flexDirection="row" margin="auto 0 0">
          <Button onClick={handleAddTrack} disabled={!selectedTrack} bgColor="#22EE22" width="140px" margin="0 20px 0 0">
            Add Track
          </Button>

          <Button onClick={handleCloseModal} bgColor="#EE2222" color="#202020" width="140px" margin="0">
            Cancel
          </Button>
        </Flex>
      </Box>
    </ModalOverlay>

  </BGContainer>;
}

export default Controls;