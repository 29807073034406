import {
    useState,
    useEffect,
    useRef
} from 'react';

import {
    useLocation
} from "react-router-dom";

import {
    useCookies
} from 'react-cookie';

import {
    testCall,
    getProfile
} from '../../connections/test';

import { getS3Endpoint } from '../../connections/uploads';

import { checkStatus, stopInstance } from '../../connections/instances';

import './home.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';

// TOASTFY
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// STYLED
import { Loader, Text, Button, Title, BGContainer } from '../../UI'

// ASSETS
import loader from '../../assets/loader.svg'

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

let checkInterval;

export default function Home({ setGameUrl }) {
    const query = useQuery();
    const [cookies, setCookie, removeCookie] = useCookies(['code']);
    const code = query.get('code');
    const [userToken, setUserToken] = useState(cookies.user_token);
    const [serverReady, setServerReady] = useState(false);
    const [unrealReady, setUnrealReady] = useState(false);
    const [serverUrl, setServerUrl] = useState('');
    const [serverIP, setServerIP] = useState('');
    const [uploading, setUploading] = useState(false);
    const [starting, setStarting] = useState(false);
    const [newLogin, setNewLogin] = useState(false);
    const [streamKey, setStreamKey] = useState('');

    const uploadButton = useRef(null);

    useEffect(() => {
        if (code) {
            setNewLogin(true);

            getProfile(code).then(response => {
                if (response.user_token) {
                    setCookie('user_token', response.user_token);
                    setUserToken(response.user_token);
                    setStarting(response.server_starting);
                    window.history.replaceState(null, 'Signed in', '/');
                }

            });
        }
    }, []);

    useEffect(() => {
        if (userToken) {
            checkInterval = setInterval(async () => {
                const status = await checkStatus(userToken);

                if (status.serverStatus.status === 'running') {
                    setServerReady(true);
                    setServerUrl(status.serverStatus.url);
                    setServerIP(status.serverStatus.ip)
                    connect(status.serverStatus.url)
                    setGameUrl(status.serverStatus.url)
                    clearInterval(checkInterval)
                }
            }, 2000)
        }
    }, [userToken])

    async function handleClick(event) {
        if (userToken && event.target.files) {
            //set the visual state in react to show the user you're busy doing something
            setUploading(true);

            //the selected file
            const _file = event.target.files[0];

            //create an array of strings split on the . character
            const _fileNameParts = _file.name.split('.');

            //pick from that array only what was after the very last . character
            const suffix = _fileNameParts[_fileNameParts.length - 1];

            //send user token, file mime type, and file suffix to the API which will ask aws to get upload endpoint and send that data to you
            const { uploadURL, videoFilename } = await getS3Endpoint(userToken, _file.type, suffix);

            console.log(uploadURL);

            //creates a new file reader, which can read out the data in the file as binary
            const fileReader = new FileReader();

            //when the file has been read... 
            fileReader.onload = async (e) => {

                //the file contents as a string of raw data
                const file = e.target.result;

                //converts the string of raw data to an array of numbers
                const binary = atob(file.split(",")[1]);
                const array = [];
                for (let i = 0; i < binary.length; ++i) {
                    array.push(binary.charCodeAt(i));
                }

                //create a new blob, which is basically a binary representation of a new file that only lives in memory, from the binary data in the original file itself
                const blobData = new Blob([new Uint8Array(array)], { type: _file.type });

                //send the blob to the url provided by AWS
                const result = await fetch(uploadURL, {
                    method: "PUT",
                    body: blobData
                });
                console.log(result);

                //set the visual state in react to show the user you're not busy anymore
                setUploading(false);
            }

            //tell the file reader to read the file selected by the user
            fileReader.readAsDataURL(event.target.files[0]);
        }
    }

    async function handleLogout() {
        await stopInstance(userToken);

        removeCookie("user_token");

        window.location.reload();
    }

    function clickUpload() {
        uploadButton.current.click();
    }

    function copyToClipboard(str) {
        return () => {
            navigator.permissions.query({ name: "clipboard-write" }).then(result => {
                if (result.state === "granted" || result.state === "prompt") {
                    navigator.clipboard.writeText(str).then(function () {
                        /* clipboard successfully set */
                        toast.success('Copied with success!', {
                            position: "top-right",
                            autoClose: 5000,
                        });
                    }, function () {
                        /* clipboard write failed */
                    });
                }
            });

        }
    }

    function redirect() {
        window.location.href = `https://livecgi.auth.us-east-1.amazoncognito.com/login?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&scope=email+openid&redirect_uri=${process.env.REACT_APP_LOGIN_REDIRECT}`


    }

    function connect(url) {
        setTimeout(() => {
            setUnrealReady(true)
        }, 10000)

        // if (!url) {
        //     return setTimeout(function () {
        //         connect(url);
        //     }, 3000);
        // }

        // var ws = new WebSocket(`ws://${url}:81`);

        // ws.onopen = function () {
        //     console.log("Connection is made")
        //     setUnrealReady(true)
        //     ws.close();
        // };

        // ws.onclose = function (e) {
        //     console.log('Socket is closed. Reconnect will be attempted in 3 seconds.', e.reason);
        //     if (!unrealReady) {
        //         setTimeout(function () {
        //             connect(url);
        //         }, 3000);
        //     }
        // };

        // ws.onerror = function (err) {
        //     console.error('Socket encountered error: ', err.message, 'Closing socket');
        //     ws.close();
        // };
    }

    function sendStreamKey() {
        toast.success(streamKey + ' stream key sent with success', {
            position: "top-right",
            autoClose: 5000,
        });

        setStreamKey('')
    }

    return (
        <BGContainer>
            <div>
                <Title>Home Page</Title>

                {!!userToken ? (
                    <div className='home-page'>

                        {serverReady && unrealReady ? (
                            <div>
                                <a href={`http://${serverUrl}:81`} target='_blank' rel='noreferrer'>
                                    <Button color="#FFFFFF" bgColor="#07bc0c">Your studio is ready</Button>
                                </a>
                                <p>Add your stream key to send the studio directly to Twitch or YouTube</p>
                                <div className="streamKeyContainer">
                                    <p>Stream Key:</p>
                                    <input placeholder="my stream key" value={streamKey} onChange={e => setStreamKey(e.target.value)} />
                                    <button margin="0 0 0 20px" width="60px" onClick={sendStreamKey}>Send</button>
                                </div>
                                <p>Give this link to your talent to stream to the studio</p>
                                <ul>
                                    <li>
                                        <p>Server: <code>rtmp://{serverIP}/live1</code> <span onClick={copyToClipboard(`rtmp://${serverIP}/live1`)}><FontAwesomeIcon icon={faClipboard} /></span></p>
                                        <p>Stream Key: <code>stream1</code> <span onClick={copyToClipboard(`stream1`)}><FontAwesomeIcon icon={faClipboard} /></span></p>
                                    </li>
                                    <li>
                                        <p>Server: <code>rtmp://{serverIP}/live2</code> <span onClick={copyToClipboard(`rtmp://${serverIP}/live2`)}><FontAwesomeIcon icon={faClipboard} /></span></p>
                                        <p>Stream Key: <code>stream2</code> <span onClick={copyToClipboard(`stream2`)}><FontAwesomeIcon icon={faClipboard} /></span></p>
                                    </li>
                                    <li>
                                        <p>Server: <code>rtmp://{serverIP}/live3</code> <span onClick={copyToClipboard(`rtmp://${serverIP}/live3`)}><FontAwesomeIcon icon={faClipboard} /></span></p>
                                        <p>Stream Key: <code>stream3</code> <span onClick={copyToClipboard(`stream3`)}><FontAwesomeIcon icon={faClipboard} /></span></p>
                                    </li>

                                </ul>
                            </div>
                        ) : (
                            <>
                                <Loader src={loader} />
                                <Text>We are turning on the lights please wait</Text>
                            </>
                        )}

                        {uploading ? (
                            <p>Your video is uploading now</p>
                        ) : (
                            <div className='upload-container'>
                                <Button onClick={clickUpload}>Upload File</Button>
                                <input ref={uploadButton} style={{ display: 'none' }} type="file" onChange={handleClick} accept="video/mp4,image/jpeg,image/png,image/gif" />
                            </div>
                        )}

                        {!starting && newLogin && (
                            <p>We have run into an issue, please wait 60 seconds and try again.</p>
                        )}

                        <Button onClick={handleLogout}>Logout</Button>
                    </div>
                ) : (
                    <Button onClick={redirect}>LOGIN</Button>
                )}

                <ToastContainer />
            </div>
        </BGContainer>
    )
}