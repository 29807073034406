export function getS3Endpoint(id_token, type, suffix) {
    return fetch(`${process.env.REACT_APP_API_HOST}/s3/endpoint`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            user_token: id_token,
            mime_type: type,
            suffix: suffix
        })
    }).then(response => {
        return response.json()
    }).catch(error => {
        console.error(error);
        return {
            error: error
        }
    })
}